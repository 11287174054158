export const GlobalUIGBtn = () => import('../../components/GlobalUI/g-btn.vue' /* webpackChunkName: "components/global-u-i-g-btn" */).then(c => wrapFunctional(c.default || c))
export const GlobalUIGCheckbox = () => import('../../components/GlobalUI/g-checkbox.vue' /* webpackChunkName: "components/global-u-i-g-checkbox" */).then(c => wrapFunctional(c.default || c))
export const GlobalUIGIcon = () => import('../../components/GlobalUI/g-icon.vue' /* webpackChunkName: "components/global-u-i-g-icon" */).then(c => wrapFunctional(c.default || c))
export const GlobalUIGInput = () => import('../../components/GlobalUI/g-input.vue' /* webpackChunkName: "components/global-u-i-g-input" */).then(c => wrapFunctional(c.default || c))
export const GlobalUIGInputPhone = () => import('../../components/GlobalUI/g-inputPhone.vue' /* webpackChunkName: "components/global-u-i-g-input-phone" */).then(c => wrapFunctional(c.default || c))
export const GlobalUIGLoader = () => import('../../components/GlobalUI/g-loader.vue' /* webpackChunkName: "components/global-u-i-g-loader" */).then(c => wrapFunctional(c.default || c))
export const GlobalUIGTextarea = () => import('../../components/GlobalUI/g-textarea.vue' /* webpackChunkName: "components/global-u-i-g-textarea" */).then(c => wrapFunctional(c.default || c))
export const GlobalUIGTogglebox = () => import('../../components/GlobalUI/g-togglebox.vue' /* webpackChunkName: "components/global-u-i-g-togglebox" */).then(c => wrapFunctional(c.default || c))
export const UIBreadcrumbs = () => import('../../components/UI/Breadcrumbs.vue' /* webpackChunkName: "components/u-i-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const UIBurger = () => import('../../components/UI/Burger.vue' /* webpackChunkName: "components/u-i-burger" */).then(c => wrapFunctional(c.default || c))
export const UICounter = () => import('../../components/UI/Counter.vue' /* webpackChunkName: "components/u-i-counter" */).then(c => wrapFunctional(c.default || c))
export const UIDelivery = () => import('../../components/UI/Delivery.vue' /* webpackChunkName: "components/u-i-delivery" */).then(c => wrapFunctional(c.default || c))
export const UIGoUp = () => import('../../components/UI/GoUp.vue' /* webpackChunkName: "components/u-i-go-up" */).then(c => wrapFunctional(c.default || c))
export const UIInput = () => import('../../components/UI/Input.vue' /* webpackChunkName: "components/u-i-input" */).then(c => wrapFunctional(c.default || c))
export const UILabels = () => import('../../components/UI/Labels.vue' /* webpackChunkName: "components/u-i-labels" */).then(c => wrapFunctional(c.default || c))
export const UILogo = () => import('../../components/UI/Logo.vue' /* webpackChunkName: "components/u-i-logo" */).then(c => wrapFunctional(c.default || c))
export const UIMediaFiles = () => import('../../components/UI/MediaFiles.vue' /* webpackChunkName: "components/u-i-media-files" */).then(c => wrapFunctional(c.default || c))
export const UIPaginations = () => import('../../components/UI/Paginations.vue' /* webpackChunkName: "components/u-i-paginations" */).then(c => wrapFunctional(c.default || c))
export const UIPasswordInput = () => import('../../components/UI/PasswordInput.vue' /* webpackChunkName: "components/u-i-password-input" */).then(c => wrapFunctional(c.default || c))
export const UIPicturesRowList = () => import('../../components/UI/PicturesRowList.vue' /* webpackChunkName: "components/u-i-pictures-row-list" */).then(c => wrapFunctional(c.default || c))
export const UIPopover = () => import('../../components/UI/Popover.vue' /* webpackChunkName: "components/u-i-popover" */).then(c => wrapFunctional(c.default || c))
export const UIProductPrice = () => import('../../components/UI/ProductPrice.vue' /* webpackChunkName: "components/u-i-product-price" */).then(c => wrapFunctional(c.default || c))
export const UIProductStatus = () => import('../../components/UI/ProductStatus.vue' /* webpackChunkName: "components/u-i-product-status" */).then(c => wrapFunctional(c.default || c))
export const UIRating = () => import('../../components/UI/Rating.vue' /* webpackChunkName: "components/u-i-rating" */).then(c => wrapFunctional(c.default || c))
export const UISlider = () => import('../../components/UI/Slider.vue' /* webpackChunkName: "components/u-i-slider" */).then(c => wrapFunctional(c.default || c))
export const UITable = () => import('../../components/UI/Table.vue' /* webpackChunkName: "components/u-i-table" */).then(c => wrapFunctional(c.default || c))
export const UITabs = () => import('../../components/UI/Tabs.vue' /* webpackChunkName: "components/u-i-tabs" */).then(c => wrapFunctional(c.default || c))
export const UIToggler = () => import('../../components/UI/Toggler.vue' /* webpackChunkName: "components/u-i-toggler" */).then(c => wrapFunctional(c.default || c))
export const SkeletonProductCard = () => import('../../components/skeleton/SkeletonProductCard.vue' /* webpackChunkName: "components/skeleton-product-card" */).then(c => wrapFunctional(c.default || c))
export const SkeletonRoomCard = () => import('../../components/skeleton/SkeletonRoomCard.vue' /* webpackChunkName: "components/skeleton-room-card" */).then(c => wrapFunctional(c.default || c))
export const SkeletonWrapper = () => import('../../components/skeleton/SkeletonWrapper.vue' /* webpackChunkName: "components/skeleton-wrapper" */).then(c => wrapFunctional(c.default || c))
export const SkeletonBanner = () => import('../../components/skeleton/banner.vue' /* webpackChunkName: "components/skeleton-banner" */).then(c => wrapFunctional(c.default || c))
export const SkeletonPoint = () => import('../../components/skeleton/point.vue' /* webpackChunkName: "components/skeleton-point" */).then(c => wrapFunctional(c.default || c))
export const SkeletonRect = () => import('../../components/skeleton/rect.vue' /* webpackChunkName: "components/skeleton-rect" */).then(c => wrapFunctional(c.default || c))
export const UIDropdown = () => import('../../components/UI/Dropdown/Dropdown.vue' /* webpackChunkName: "components/u-i-dropdown" */).then(c => wrapFunctional(c.default || c))
export const UIDropdownSelect = () => import('../../components/UI/Dropdown/DropdownSelect.vue' /* webpackChunkName: "components/u-i-dropdown-select" */).then(c => wrapFunctional(c.default || c))
export const UIGallery = () => import('../../components/UI/Gallery/Gallery.vue' /* webpackChunkName: "components/u-i-gallery" */).then(c => wrapFunctional(c.default || c))
export const UIModal = () => import('../../components/UI/Modal/Modal.vue' /* webpackChunkName: "components/u-i-modal" */).then(c => wrapFunctional(c.default || c))
export const UIModalOverlay = () => import('../../components/UI/Modal/ModalOverlay.vue' /* webpackChunkName: "components/u-i-modal-overlay" */).then(c => wrapFunctional(c.default || c))
export const UIModalProfileModal = () => import('../../components/UI/Modal/ProfileModal.vue' /* webpackChunkName: "components/u-i-modal-profile-modal" */).then(c => wrapFunctional(c.default || c))
export const UISearch = () => import('../../components/UI/Search/Search.vue' /* webpackChunkName: "components/u-i-search" */).then(c => wrapFunctional(c.default || c))
export const UISearchList = () => import('../../components/UI/Search/SearchList.vue' /* webpackChunkName: "components/u-i-search-list" */).then(c => wrapFunctional(c.default || c))
export const UISearchWrapper = () => import('../../components/UI/Search/SearchWrapper.vue' /* webpackChunkName: "components/u-i-search-wrapper" */).then(c => wrapFunctional(c.default || c))
export const UISliderArrowsDarkArrows = () => import('../../components/UI/SliderArrows/DarkArrows.vue' /* webpackChunkName: "components/u-i-slider-arrows-dark-arrows" */).then(c => wrapFunctional(c.default || c))
export const UISliderArrowsDefaultArrows = () => import('../../components/UI/SliderArrows/DefaultArrows.vue' /* webpackChunkName: "components/u-i-slider-arrows-default-arrows" */).then(c => wrapFunctional(c.default || c))
export const LayoutsFiltersLayout = () => import('../../components/layouts/Filters/FiltersLayout.vue' /* webpackChunkName: "components/layouts-filters-layout" */).then(c => wrapFunctional(c.default || c))
export const LayoutsFooter = () => import('../../components/layouts/Footer/Footer.vue' /* webpackChunkName: "components/layouts-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutsHeader = () => import('../../components/layouts/Header/Header.vue' /* webpackChunkName: "components/layouts-header" */).then(c => wrapFunctional(c.default || c))
export const ModulesCardsCompanyDeliveryAddressCard = () => import('../../components/modules/Cards/CompanyDeliveryAddressCard.vue' /* webpackChunkName: "components/modules-cards-company-delivery-address-card" */).then(c => wrapFunctional(c.default || c))
export const ModulesCardsCompanyInfoCard = () => import('../../components/modules/Cards/CompanyInfoCard.vue' /* webpackChunkName: "components/modules-cards-company-info-card" */).then(c => wrapFunctional(c.default || c))
export const ModulesCardsProductItemCard = () => import('../../components/modules/Cards/ProductItemCard.vue' /* webpackChunkName: "components/modules-cards-product-item-card" */).then(c => wrapFunctional(c.default || c))
export const ModulesCartAddPromocode = () => import('../../components/modules/Cart/AddPromocode.vue' /* webpackChunkName: "components/modules-cart-add-promocode" */).then(c => wrapFunctional(c.default || c))
export const ModulesCartApplyLoyaltyProgram = () => import('../../components/modules/Cart/ApplyLoyaltyProgram.vue' /* webpackChunkName: "components/modules-cart-apply-loyalty-program" */).then(c => wrapFunctional(c.default || c))
export const ModulesMiniaturesCategoryMiniatureItem = () => import('../../components/modules/Miniatures/CategoryMiniatureItem.vue' /* webpackChunkName: "components/modules-miniatures-category-miniature-item" */).then(c => wrapFunctional(c.default || c))
export const ModulesMiniaturesProductMicroInfoMiniature = () => import('../../components/modules/Miniatures/ProductMicroInfoMiniature.vue' /* webpackChunkName: "components/modules-miniatures-product-micro-info-miniature" */).then(c => wrapFunctional(c.default || c))
export const ModulesMiniaturesProductMiniatureItem = () => import('../../components/modules/Miniatures/ProductMiniatureItem.vue' /* webpackChunkName: "components/modules-miniatures-product-miniature-item" */).then(c => wrapFunctional(c.default || c))
export const ModulesMiniaturesRoomMiniatureItem = () => import('../../components/modules/Miniatures/RoomMiniatureItem.vue' /* webpackChunkName: "components/modules-miniatures-room-miniature-item" */).then(c => wrapFunctional(c.default || c))
export const ModulesModalWindowsAuthModal = () => import('../../components/modules/ModalWindows/AuthModal.vue' /* webpackChunkName: "components/modules-modal-windows-auth-modal" */).then(c => wrapFunctional(c.default || c))
export const ModulesModalWindowsLogin = () => import('../../components/modules/ModalWindows/Login.vue' /* webpackChunkName: "components/modules-modal-windows-login" */).then(c => wrapFunctional(c.default || c))
export const ModulesModalCart = () => import('../../components/modules/ModalWindows/ModalCart.vue' /* webpackChunkName: "components/modules-modal-cart" */).then(c => wrapFunctional(c.default || c))
export const ModulesModalRoom = () => import('../../components/modules/ModalWindows/ModalRoom.vue' /* webpackChunkName: "components/modules-modal-room" */).then(c => wrapFunctional(c.default || c))
export const ModulesModalWindowsProductVariationsModal = () => import('../../components/modules/ModalWindows/ProductVariationsModal.vue' /* webpackChunkName: "components/modules-modal-windows-product-variations-modal" */).then(c => wrapFunctional(c.default || c))
export const ModulesModalWindowsRegister = () => import('../../components/modules/ModalWindows/Register.vue' /* webpackChunkName: "components/modules-modal-windows-register" */).then(c => wrapFunctional(c.default || c))
export const ModulesModalWindowsUpdateUserFormModal = () => import('../../components/modules/ModalWindows/UpdateUserFormModal.vue' /* webpackChunkName: "components/modules-modal-windows-update-user-form-modal" */).then(c => wrapFunctional(c.default || c))
export const ModulesPopoverBasket = () => import('../../components/modules/PopoverBlocks/PopoverBasket.vue' /* webpackChunkName: "components/modules-popover-basket" */).then(c => wrapFunctional(c.default || c))
export const ModulesPopoverContacts = () => import('../../components/modules/PopoverBlocks/PopoverContacts.vue' /* webpackChunkName: "components/modules-popover-contacts" */).then(c => wrapFunctional(c.default || c))
export const ModulesPopoverDefault = () => import('../../components/modules/PopoverBlocks/PopoverDefault.vue' /* webpackChunkName: "components/modules-popover-default" */).then(c => wrapFunctional(c.default || c))
export const ModulesPopoverFavorites = () => import('../../components/modules/PopoverBlocks/PopoverFavorites.vue' /* webpackChunkName: "components/modules-popover-favorites" */).then(c => wrapFunctional(c.default || c))
export const ModulesPopoverLocale = () => import('../../components/modules/PopoverBlocks/PopoverLocale.vue' /* webpackChunkName: "components/modules-popover-locale" */).then(c => wrapFunctional(c.default || c))
export const ModulesPopoverUserMenu = () => import('../../components/modules/PopoverBlocks/PopoverUserMenu.vue' /* webpackChunkName: "components/modules-popover-user-menu" */).then(c => wrapFunctional(c.default || c))
export const ModulesPopoverBlocksWarranty = () => import('../../components/modules/PopoverBlocks/warranty.vue' /* webpackChunkName: "components/modules-popover-blocks-warranty" */).then(c => wrapFunctional(c.default || c))
export const ModulesReviewsReviewFeedback = () => import('../../components/modules/Reviews/ReviewFeedback.vue' /* webpackChunkName: "components/modules-reviews-review-feedback" */).then(c => wrapFunctional(c.default || c))
export const ModulesReviewsReviewItem = () => import('../../components/modules/Reviews/ReviewItem.vue' /* webpackChunkName: "components/modules-reviews-review-item" */).then(c => wrapFunctional(c.default || c))
export const ModulesReviewsReviewResult = () => import('../../components/modules/Reviews/ReviewResult.vue' /* webpackChunkName: "components/modules-reviews-review-result" */).then(c => wrapFunctional(c.default || c))
export const ModulesReviewsHead = () => import('../../components/modules/Reviews/ReviewsHead.vue' /* webpackChunkName: "components/modules-reviews-head" */).then(c => wrapFunctional(c.default || c))
export const ModulesReviewsModule = () => import('../../components/modules/Reviews/ReviewsModule.vue' /* webpackChunkName: "components/modules-reviews-module" */).then(c => wrapFunctional(c.default || c))
export const ModulesSlidersCategoriesSlider = () => import('../../components/modules/Sliders/CategoriesSlider.vue' /* webpackChunkName: "components/modules-sliders-categories-slider" */).then(c => wrapFunctional(c.default || c))
export const ModulesSlidersProductsSlider = () => import('../../components/modules/Sliders/ProductsSlider.vue' /* webpackChunkName: "components/modules-sliders-products-slider" */).then(c => wrapFunctional(c.default || c))
export const ModulesSlidersRoomsSlider = () => import('../../components/modules/Sliders/RoomsSlider.vue' /* webpackChunkName: "components/modules-sliders-rooms-slider" */).then(c => wrapFunctional(c.default || c))
export const PagesAuthOverlay = () => import('../../components/pages/Auth/AuthOverlay.vue' /* webpackChunkName: "components/pages-auth-overlay" */).then(c => wrapFunctional(c.default || c))
export const PagesMainCompareListsProducts = () => import('../../components/pages/Main/CompareListsProducts.vue' /* webpackChunkName: "components/pages-main-compare-lists-products" */).then(c => wrapFunctional(c.default || c))
export const PagesMainBannerSlider = () => import('../../components/pages/Main/MainBannerSlider.vue' /* webpackChunkName: "components/pages-main-banner-slider" */).then(c => wrapFunctional(c.default || c))
export const PagesMainCategories = () => import('../../components/pages/Main/MainCategories.vue' /* webpackChunkName: "components/pages-main-categories" */).then(c => wrapFunctional(c.default || c))
export const PagesMainHeaderSection = () => import('../../components/pages/Main/MainHeaderSection.vue' /* webpackChunkName: "components/pages-main-header-section" */).then(c => wrapFunctional(c.default || c))
export const PagesMainProducts = () => import('../../components/pages/Main/MainProducts.vue' /* webpackChunkName: "components/pages-main-products" */).then(c => wrapFunctional(c.default || c))
export const PagesMainRooms = () => import('../../components/pages/Main/MainRooms.vue' /* webpackChunkName: "components/pages-main-rooms" */).then(c => wrapFunctional(c.default || c))
export const PagesProductDescriptions = () => import('../../components/pages/Product/ProductDescriptions.vue' /* webpackChunkName: "components/pages-product-descriptions" */).then(c => wrapFunctional(c.default || c))
export const PagesProfileNavSide = () => import('../../components/pages/Profile/NavSide.vue' /* webpackChunkName: "components/pages-profile-nav-side" */).then(c => wrapFunctional(c.default || c))
export const LayoutsFiltersComponentsDropdownFilter = () => import('../../components/layouts/Filters/components/DropdownFilter.vue' /* webpackChunkName: "components/layouts-filters-components-dropdown-filter" */).then(c => wrapFunctional(c.default || c))
export const LayoutsFooterContacts = () => import('../../components/layouts/Footer/components/FooterContacts.vue' /* webpackChunkName: "components/layouts-footer-contacts" */).then(c => wrapFunctional(c.default || c))
export const LayoutsFooterListNavbar = () => import('../../components/layouts/Footer/components/FooterListNavbar.vue' /* webpackChunkName: "components/layouts-footer-list-navbar" */).then(c => wrapFunctional(c.default || c))
export const LayoutsHeaderActions = () => import('../../components/layouts/Header/components/HeaderActions.vue' /* webpackChunkName: "components/layouts-header-actions" */).then(c => wrapFunctional(c.default || c))
export const LayoutsHeaderDropdownMenu = () => import('../../components/layouts/Header/components/HeaderDropdownMenu.vue' /* webpackChunkName: "components/layouts-header-dropdown-menu" */).then(c => wrapFunctional(c.default || c))
export const LayoutsHeaderNavbar = () => import('../../components/layouts/Header/components/HeaderNavbar.vue' /* webpackChunkName: "components/layouts-header-navbar" */).then(c => wrapFunctional(c.default || c))
export const LayoutsHeaderPopoverAction = () => import('../../components/layouts/Header/components/HeaderPopoverAction.vue' /* webpackChunkName: "components/layouts-header-popover-action" */).then(c => wrapFunctional(c.default || c))
export const LayoutsHeaderSearch = () => import('../../components/layouts/Header/components/HeaderSearch.vue' /* webpackChunkName: "components/layouts-header-search" */).then(c => wrapFunctional(c.default || c))
export const ModulesModalWindowsAuthLoginModal = () => import('../../components/modules/ModalWindows/Auth/LoginModal.vue' /* webpackChunkName: "components/modules-modal-windows-auth-login-modal" */).then(c => wrapFunctional(c.default || c))
export const PagesAuthCongratulations = () => import('../../components/pages/Auth/components/AuthCongratulations.vue' /* webpackChunkName: "components/pages-auth-congratulations" */).then(c => wrapFunctional(c.default || c))
export const PagesAuthForgotPass = () => import('../../components/pages/Auth/components/AuthForgotPass.vue' /* webpackChunkName: "components/pages-auth-forgot-pass" */).then(c => wrapFunctional(c.default || c))
export const PagesAuthLogin = () => import('../../components/pages/Auth/components/AuthLogin.vue' /* webpackChunkName: "components/pages-auth-login" */).then(c => wrapFunctional(c.default || c))
export const PagesAuthNewPass = () => import('../../components/pages/Auth/components/AuthNewPass.vue' /* webpackChunkName: "components/pages-auth-new-pass" */).then(c => wrapFunctional(c.default || c))
export const PagesAuthComponentsSignUp = () => import('../../components/pages/Auth/components/SignUp.vue' /* webpackChunkName: "components/pages-auth-components-sign-up" */).then(c => wrapFunctional(c.default || c))
export const PagesAuthComponentsSignUpSuccess = () => import('../../components/pages/Auth/components/SignUpSuccess.vue' /* webpackChunkName: "components/pages-auth-components-sign-up-success" */).then(c => wrapFunctional(c.default || c))
export const PagesProductAuthBlock = () => import('../../components/pages/Product/components/ProductAuthBlock.vue' /* webpackChunkName: "components/pages-product-auth-block" */).then(c => wrapFunctional(c.default || c))
export const PagesProductDescriptionsActions = () => import('../../components/pages/Product/components/ProductDescriptionsActions.vue' /* webpackChunkName: "components/pages-product-descriptions-actions" */).then(c => wrapFunctional(c.default || c))
export const PagesProductNavigation = () => import('../../components/pages/Product/components/ProductNavigation.vue' /* webpackChunkName: "components/pages-product-navigation" */).then(c => wrapFunctional(c.default || c))
export const PagesProductNavigationList = () => import('../../components/pages/Product/components/ProductNavigationList.vue' /* webpackChunkName: "components/pages-product-navigation-list" */).then(c => wrapFunctional(c.default || c))
export const PagesProfileCompanyAddress = () => import('../../components/pages/Profile/company/CompanyAddress.vue' /* webpackChunkName: "components/pages-profile-company-address" */).then(c => wrapFunctional(c.default || c))
export const PagesProfileCompanyInfo = () => import('../../components/pages/Profile/company/CompanyInfo.vue' /* webpackChunkName: "components/pages-profile-company-info" */).then(c => wrapFunctional(c.default || c))
export const PagesProfileCompanyUsers = () => import('../../components/pages/Profile/company/CompanyUsers.vue' /* webpackChunkName: "components/pages-profile-company-users" */).then(c => wrapFunctional(c.default || c))
export const ModulesModalEditCashOnDelivery = () => import('../../components/modules/ModalWindows/profile/checkout/ModalEditCashOnDelivery.vue' /* webpackChunkName: "components/modules-modal-edit-cash-on-delivery" */).then(c => wrapFunctional(c.default || c))
export const ModulesModalEditComment = () => import('../../components/modules/ModalWindows/profile/checkout/ModalEditComment.vue' /* webpackChunkName: "components/modules-modal-edit-comment" */).then(c => wrapFunctional(c.default || c))
export const ModulesModalEditDeliveryAddress = () => import('../../components/modules/ModalWindows/profile/checkout/ModalEditDeliveryAddress.vue' /* webpackChunkName: "components/modules-modal-edit-delivery-address" */).then(c => wrapFunctional(c.default || c))
export const ModulesModalEditDeliveryMethod = () => import('../../components/modules/ModalWindows/profile/checkout/ModalEditDeliveryMethod.vue' /* webpackChunkName: "components/modules-modal-edit-delivery-method" */).then(c => wrapFunctional(c.default || c))
export const ModulesModalEditPayment = () => import('../../components/modules/ModalWindows/profile/checkout/ModalEditPayment.vue' /* webpackChunkName: "components/modules-modal-edit-payment" */).then(c => wrapFunctional(c.default || c))
export const ModulesModalEditPaymentMethod = () => import('../../components/modules/ModalWindows/profile/checkout/ModalEditPaymentMethod.vue' /* webpackChunkName: "components/modules-modal-edit-payment-method" */).then(c => wrapFunctional(c.default || c))
export const ModulesModalWindowsProfileCompanyAddressModal = () => import('../../components/modules/ModalWindows/profile/company/CompanyAddressModal.vue' /* webpackChunkName: "components/modules-modal-windows-profile-company-address-modal" */).then(c => wrapFunctional(c.default || c))
export const ModulesModalWindowsProfileCompanyUsersModal = () => import('../../components/modules/ModalWindows/profile/company/CompanyUsersModal.vue' /* webpackChunkName: "components/modules-modal-windows-profile-company-users-modal" */).then(c => wrapFunctional(c.default || c))
export const ModulesModalWindowsProfileCompanyEditCompanyInfoModal = () => import('../../components/modules/ModalWindows/profile/company/EditCompanyInfoModal.vue' /* webpackChunkName: "components/modules-modal-windows-profile-company-edit-company-info-modal" */).then(c => wrapFunctional(c.default || c))
export const ModulesModalOrderRefund = () => import('../../components/modules/ModalWindows/profile/order/ModalOrderRefund.vue' /* webpackChunkName: "components/modules-modal-order-refund" */).then(c => wrapFunctional(c.default || c))
export const ModulesModalWindowsProfileReviewsAddReviewModal = () => import('../../components/modules/ModalWindows/profile/reviews/AddReviewModal.vue' /* webpackChunkName: "components/modules-modal-windows-profile-reviews-add-review-modal" */).then(c => wrapFunctional(c.default || c))
export const ModulesModalWindowsProfileReviewsWatchReviewModal = () => import('../../components/modules/ModalWindows/profile/reviews/WatchReviewModal.vue' /* webpackChunkName: "components/modules-modal-windows-profile-reviews-watch-review-modal" */).then(c => wrapFunctional(c.default || c))
export const ModulesModalWindowsProfileTicketsAddTicketModal = () => import('../../components/modules/ModalWindows/profile/tickets/AddTicketModal.vue' /* webpackChunkName: "components/modules-modal-windows-profile-tickets-add-ticket-modal" */).then(c => wrapFunctional(c.default || c))
export const ModulesModalWindowsProfileTicketsTicketTableDetailInfo = () => import('../../components/modules/ModalWindows/profile/tickets/TicketTableDetailInfo.vue' /* webpackChunkName: "components/modules-modal-windows-profile-tickets-ticket-table-detail-info" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
